import React from "react"

import * as typeformEmbed from '@typeform/embed'


class TypeForm extends React.Component {
    componentDidMount() {
        const el = document.getElementById("typeform-embed");
        const options = {
            hideHeaders: true,
            hideFooter: true,
            opacity: 0

        }
        typeformEmbed.makeWidget( el, 'https://stevenkreschollek.typeform.com/to/mPj4Uu', options )
    }


    render() {
        return (
            <div id="typeform-embed" style={{ width: '100%', height: '100%' }} />
        )
    }
}

export default TypeForm