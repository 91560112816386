import React from "react"
import ReactPhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'


const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class ConnectForm extends React.Component {

  constructor(props) {
    super(props);


    this.state = this.getInitialState(props);
  }


  getInitialState(props) {




    return {
      name: '',
      email: '',
      details: '',
      phone: '',
      formName: props.formName
    };
  }

  handleChange = e => {
    if ( e.target.name === 'details' && e.target.value.length > 0 ) {
      e.target.classList.add('textarea--entered');
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePhoneChange = v => {
    this.setState({ phone: v })
  }

  handleSubmit = e => {
    e.preventDefault();

    const formValues = {
      name: this.state.name,
      email: this.state.email,
      details: this.state.details,
      phone: this.state.phone
    }

    const { formName } = this.state;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, ...formValues })
    })
      .then((response) =>  this.renderThankYouModal(response))
      .catch(error => alert(error));


  };

  renderThankYouModal = (response) => {

    let formAlert = document.getElementsByClassName('form-alert');
    for (let i = 0; i < formAlert.length; i++) {
      const element = formAlert[i];
      element.classList.add('active');
      window.setTimeout(() => {
        element.classList.remove('active')
      }, 4500);
      this.setState({
        name: '',
        email: '',
        details: '',
        phone: ''
      });
      
    }

  }


  render () {
    const {
      name,
      email,
      details,
      phone,
      formName
    } = this.state;

    return (
      <>
      <section className="meetContact">
        <div className="meetContact__inner">
          <form className="meetContact__form container" method="POST" name={formName} onSubmit={this.handleSubmit} data-netlify="true">
            <input type="hidden" name="form-name" value={formName} />

            <div className="row">

            <div className="col-xs-12 col-sm-12 col-md-4 cell border">
              <label className="show-for-sr" id="name">Name:</label>
              <input type="text" name="name" placeholder="NAME"  aria-labelledby="name" value={name} onChange={this.handleChange}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 cell border">
              <label className="show-for-sr" id="email">E-mail (Required):</label>
              <input type="text" name="email" placeholder="EMAIL*"  aria-labelledby="email" value={email} onChange={this.handleChange} required/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 cell border">
              <label className="show-for-sr" id="phone">Phone:</label>
              <ReactPhoneInput 
                disableAreaCodes={true}
                // disableCountryCode={true}
                defaultCountry='us'
                regions={['america', 'europe', 'oceania']}
                // placeholder="+1" 
                inputClass="special-input"
                buttonStyle={{borderRadius: 0, border: "none", backgroundColor: "#fff"}}
                containerStyle={{margin:"10px 0"}}
                inputStyle={{maxWidth: '100%', width: "100%", borderRadius: 0,background: 'rgba(255, 255, 255, 1)', border: 'none'}} 
                value={phone}
                inputExtraProps={{
                  name: 'phone',
                  'aria-labelledby': "phone"
                }}
                onChange={this.handlePhoneChange}
              />
            </div>
            </div>
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 cell border">
              <label className="show-for-sr" id="details">What can we help you with?</label>
              <textarea type="text" name="details" placeholder="WHAT CAN WE HELP YOU WITH"  aria-labelledby="details" value={details} onChange={this.handleChange}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 cell no-border">
              <button type="submit" value="submit">LET'S CONNECT</button>
            </div>

            <span className="cell col-sm-12 form-alert">
                <p>Thanks! We'll be in touch within the next 24 hours or less!</p>
            </span>

            </div>

          </form>
        </div>
      </section>
      </>
    )
  }
}

export default ConnectForm
