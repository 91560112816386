import React from "react"
// import { Link } from "gatsby"

import styled from '@emotion/styled'

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Header from "../components/header"

import Typing from "../components/typing"
import JoinNow from "../components/joinnow"
import Icon from "../components/icon"

import ConnectForm from "../components/connect-form"


import HeroImageDesktop from "../assets/images/hero-image-desktop.svg"
import HeroImageMobile from "../assets/images/hero-image-mobile.svg"
import MobileImageBottom from "../assets/images/mobile-header-bottom.svg"

import UpperFlurish from "../assets/images/upper-flourish.svg"
import LowerFlourish from "../assets/images/lower-flourish.svg"
// import HeroImageMedium from ""
// import HeroImageSmall from ""

import TopRight from "../assets/images/top-right.svg"

import DeskIcon from "../assets/images/icons/desk.svg"
import PlaneIcon from "../assets/images/icons/plane.svg"
import ServerIcon from "../assets/images/icons/servers.svg"

import LinkedIn from "../assets/images/icons/linkedin.svg"
import Facebook from "../assets/images/icons/facebook.svg"
// import Twitter from "../assets/images/icons/twitter.svg"

import Map from "../components/map"

const SpecialSection = styled.section`
  position:relative;

  @media (max-width: 767px) {
    background-color:#03D3C6;
    margin-bottom: 20vw;
    &:after {

    background: url(${MobileImageBottom});
    content: "";
    position: absolute;
    background-size: auto 100%;
    width: 100%;
    height: 35vw;
    bottom: -8vw;
    }
  }
`

const SpecialImage = styled.img`
  position:absolute;
  top:0;
  right:0;
  height:50vw;

  @media (max-width: 767px) {
    display: none;
  }
`

const FlourishSection = styled.section`
  position:relative;
  // padding: 98px 1.5rem;
  background-color: #AADD63;
  margin: calc(75px + 3em) 0;

  &:before {
    background-image:url(${UpperFlurish});
    top: -75px;
  }
  &:after {
    background-image:url(${LowerFlourish});
    bottom: -75px;
  }

  &:before, &:after {
    
    background-repeat: repeat-x;
    background-size: cover;
    background-position: top left;
    width: 100%;
    height: 97px;
    content: "";
    position: absolute;
    
    left: 0;
    background-size: auto 100%;
  }

`

const SpecialDiv = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    right: -25%;
    bottom: -28vw;
    z-index: 1;
  }

`

const LeftAlignSection = styled.section`
  @media (min-width: 767px) {
    text-align:center;
  }

`

const IndexPage = () => (
  <Layout>
    <SEO 
      title="Tech Companies in Arizona"
      description="NATA is an alliance of tech companies in Arizona that are seeking to improve viability, networking, and communication between these companies, their communities and education organizations."
    />
    <SpecialSection className="intro">
      <SpecialImage alt="Northern Arizona Technology Alliance" src={TopRight} />
      <div className="container" style={{padding:"8rem 1.5rem 5rem", position: "relative", zIndex: "2"}}>
        
        <div className="row">
          <div className="col-xs-11 col-sm-5 col-md-6" style={{display:'flex', alignItems: 'center'}}>

            <div>
              <h1 className="text--page-heading">
                Tech for you<br />
                <Typing line1="in Arizona" line2="wherever you are." />
              </h1>
              <JoinNow />
            </div>
          </div>
          <SpecialDiv className="col-xs-8 col-sm-7 col-md-6">
            <picture>
                <source media="(min-width: 1024px)" srcSet={HeroImageDesktop} />
                {/* <source media="(min-width: 465px)" srcset="img_white_flower.jpg" /> */}
                <img src={HeroImageMobile} alt="Technology workers for your needs" style={{display: "block", width: "100%", height: "auto"}} />
            </picture>
          </SpecialDiv>
        </div>
      </div>

      <div className="special">

      </div>
    </SpecialSection>

    

    <LeftAlignSection className="container">
      <div className="row center-sm">
        <div className="col-xs-10">
          <h1 style={{margin: "0"}}>Northern Arizona Technology Alliance</h1>
        </div>
      </div>
      <div className="row center-sm">
        <div className="col-xs-11 col-sm-10">
          <p>The vision of NATA is to expand the tech community by attracting technology companies and investment to Northern Arizona, facilitating innovation opportunities for existing businesses to compete in global markets, and liaison with economic development organizations to promote economic growth in high-wage jobs.</p>

          
        </div>
      </div>
    </LeftAlignSection>


    <FlourishSection>


            <ConnectForm />

    </FlourishSection>

    <section className="container">
      <div className="row center-sm">
        <div className="col-md-12" style={{textAlign: "center", padding: "3em 0"}}>
          <h1>Our initiatives will make technology accessible</h1>
        </div>
        <Icon className="col-xs-12 col-sm-6 col-md-4" src={ServerIcon} alt="Server rack illustration">
          <p>Digital Technology</p>
        </Icon>
        <Icon className="col-xs-12 col-sm-6 col-md-4" src={PlaneIcon} alt="Illustration of a plane">
          <p>Aerospace</p>
        </Icon>
        <Icon className="col-xs-12 col-sm-6 col-md-4" src={DeskIcon} alt="Technological workforce illustration">
          <p>Workforce</p>
        </Icon>
      </div>
    </section>

    <section className="container">
      <div className="row center-xs">
        <div className="col-xs-12" style={{textAlign: "center", padding: "1.5em 0 0"}}>
          <h1>Upcoming Events</h1>
        </div>
        <div className="col-xs-12 col-sm-8">
          <Map />
        </div>
      </div>
    </section>
    
    <aside className="container" style={{textAlign:"center", padding: "1.5em 1em 0"}}>
      <h1>NATA supports technology organizations in Arizona.<br />We build with what we believe in.</h1>
    </aside>

    <section className="container">
      <div className="row center-xs">
        <div className="col-md-12" style={{textAlign: "center", padding: "0 0 3em"}}>
          <h1 className="underline">Keep in touch</h1>

          <nav className="social-nav">
            <ul>
              <li><a href="https://www.linkedin.com/company/tech-arizona" target="_blank" rel="noopener noreferrer"><img src={LinkedIn} alt="LinkedIn" /></a></li>
              <li><a href="https://www.facebook.com/NorthernArizonaTechAlliance/" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a></li>
              {/* <li><a href="#test" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="Twitter" /></a></li> */}
            </ul>
          </nav>

          <a href="mailto:info@tech-arizona.com">info@tech-arizona.com</a>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
