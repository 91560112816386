import React from 'react'

import styled from '@emotion/styled'


class Typing extends React.Component {
    constructor(props) {
        super(props);

        this.typingFunction = this.typingFunction.bind(this);
        this.state = { message: '' }

        this.curIndex = 0;
        this.curLine = 1;
        this.activeLine = 1;
        this.finished = false;

        this.timeout = null;

    }

    typingFunction() {
        const speed = 95;
        const { line1, line2 } = this.props;
        const { message } = this.state;
        

        if ( this.curIndex < line1.length && this.curLine === 1) {
            this.setState( {
                message: message + line1.charAt(this.curIndex)
            } );
            this.curIndex++;
            this.timeout = setTimeout(this.typingFunction, speed);
        } else if (this.curIndex === line1.length && this.curLine === 1 ) {
            this.curIndex = 0;
            this.curLine = 2;
            this.timeout = setTimeout(this.typingFunction, (speed + 1000));
        } else if (this.curLine === 2 ) {

            if ( this.curIndex <= line1.length && this.activeLine === 1 ) {
                let deleteMsg = line1.substring(0, line1.length - this.curIndex).length > 0 ? line1.substring(0, line1.length - this.curIndex) : "";
                this.setState( {
                    message: deleteMsg
                } );

                this.curIndex++;
                this.timeout = setTimeout(this.typingFunction, speed / 1.5);
                       
            }
            if ( this.curIndex === ( line1.length + 1 ) && this.activeLine === 1) {
                this.curIndex = 0;
                this.activeLine = 2;
                       
            }
            if ( this.activeLine === 2 && this.curIndex <= line2.length ) {
                this.setState( {
                    message: this.state.message + line2.charAt(this.curIndex)
                } );
                this.curIndex++
                
            }

            
            if ( this.activeLine === 2 && this.curIndex === line2.length ) {
                this.finished = true;
            }

            if (!this.finished && this.activeLine === 2) {
                this.timeout = setTimeout(this.typingFunction, speed);
            }
             
        }
    }

    componentDidMount() {
        this.timeout = setTimeout(() => this.typingFunction(), 1200);
    }

    componentWillUnmount() {
      clearTimeout(this.timeout)
    }


    render() {
        const { message } = this.state;
        return (
            <Span>{message}</Span>
        )
    }
}

export default Typing



const Span = styled.span`
    display:inline-block;

    &:after {
        content: "|";
        animation: 1s blink step-end infinite;

    }

    @keyframes blink {
        from, to {
            color: transparent;
          }
          50% {
            color: inherit;
          }
    }
`