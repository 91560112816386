import React from 'react';
import ReactTooltip from 'react-tooltip'


class Map extends React.Component {
    constructor(props) {
        super(props);

        this.FullCalendar = false;
        this.dayGridPlugin = false;
        this.googleCalendarPlugin = false;
        this.list = false;

        this.state = { ready: false };
    }
    calendarRef = React.createRef()


    componentDidMount() {
        this.FullCalendar = require('@fullcalendar/react').default;
        this.dayGridPlugin = require('@fullcalendar/daygrid').default;
        this.googleCalendarPlugin = require('@fullcalendar/google-calendar').default;
        this.list = require('@fullcalendar/list').default;

        this.setState({ready: true}, () => {
            this.updateDimensions();
            window.addEventListener('resize', this.updateDimensions);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        const w = window,
              d = document,
              documentElement = d.documentElement,
              body = d.getElementsByTagName('body')[0],
              width = w.innerWidth || documentElement.clientWidth || body.clientWidth;

        this.setView( width );
    }

    setView = ( width ) => {
        let calendarApi = this.calendarRef.current.getApi();
        if ( width > 767 ) {
            calendarApi.changeView('dayGridMonth');
        } else {
            calendarApi.changeView('listMonth');
        }
    }

    handleEventPositioned = info => {
        info.el.setAttribute("data-tip",info.event.title);
        ReactTooltip.rebuild();
    }

    eventRender = info => {
        if ( info.event.title.indexOf('NATA') > -1 )  {
            info.el.classList.add('nata-event');
        }
    }

    handleClick = info => {
        info.jsEvent.preventDefault();

        if ( info.event.url ) {
            window.open( info.event.url, 'gcalevent', 'width=700,height=600' );
        }
    }


    render() {
        const { FullCalendar, dayGridPlugin, list, googleCalendarPlugin } = this;
        const { ready } = this.state;

        return(
            <>
                { ready && 
                    <>
                        <FullCalendar 
                            ref={this.calendarRef}
                            defaultView='listMonth'
                            plugins={[ dayGridPlugin, googleCalendarPlugin, list ]}
                            googleCalendarApiKey='AIzaSyAkB4K4xllIUobWFVKE3CFb8p6Z8kMMZhQ'
                            events={{ 
                                googleCalendarId: 'tech-arizona.com_0o5j0i3j4r2jrr1beps7tl69p8@group.calendar.google.com' 
                                }}
                            eventPositioned={this.handleEventPositioned}
                            eventClick={this.handleClick}
                            eventRender={this.eventRender}
                        />
                        <ReactTooltip />
                    </>
                }                
            </>
        )
    }
}

export default Map;