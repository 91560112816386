import React from 'react'
import styled from '@emotion/styled'

import TypeForm from './typeform'


class JoinNow extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    

    _handleKeyDown = (event) => {
       


        if ( event.keyCode === 27 ) {
            console.log('exit!');
            this.closeModal();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    handleClick(e) {
        console.log(e.clientX);
        console.log(e);
        const offsets = e.target.getBoundingClientRect();
        const modal = document.getElementById('modal');

        // console.log(modal);
        document.body.classList.add('modal-active');
        modal.style.top = offsets.top + "px";
        modal.style.left = offsets.left + "px";

        modal.classList.add('active');

        setTimeout(() => { modal.classList.add('transitioned') }, 250);
        

    }

    closeModal(e) {
        const modal = document.getElementById('modal');
        modal.classList.remove('active');
        modal.classList.remove('transitioned');
        document.body.classList.remove('modal-active');
        
    }

    render() {
        return (
            <>
                <Button
                    onClick={(e) => this.handleClick(e)}
                >
                    Join Now
                </Button>

                <Modal id="modal">
                    <CloseModal onClick={(e) => this.closeModal(e)}>X</CloseModal>
                    {/* <p>Wee</p> */}
                    <TypeForm />
                </Modal>
            </>
        )
    }
}

export default JoinNow

const Button = styled.button`
    background: #8DC63F;
    border: none;
    border-radius: 50px;
    padding: 1em 3em;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    font-size: 1.2em;
    

    box-shadow: 2px 2px 5px -1px rgba(0,0,0,0.15);
    transition: box-shadow 0.1s linear;

    &:hover {
        box-shadow: none;
    }

    @media (max-width: 767px) {
        font-size: 0.9em;
    }


`

const CloseModal = styled.span`
position: fixed;
top: 1em;
right: 1em;
color: white;
z-index: 9999;
font-family: sans-serif;
font-size: 2em;
line-height: 1rem;
cursor: pointer;

&:hover {
    text-shadow: 0 0 4px rgba(0,0,0,0.3);
}
`

const Modal = styled.aside`
    position:fixed;
    width: 60px;
    height: 20px;
    top: 0;
    left: 0;
    content: "";
    border-radius: 300px;
    background: #8DC63F;
    opacity:0;
    // transition: all 4s linear;
    // animation-name: modalTransition;
    // transition-timing-function: ease-in;
    animation-timing-function: cubic-bezier(.47,.52,0,1.01);
    animation-duration: 0.5s;
    // animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: none;
    overflow: hidden;
    z-index: 99999;

    &.active {
        display:block;
        animation-name: modalTransition;
    }

    #typeform-embed { display: none }
    &.transitioned #typeform-embed { display: block }


    @keyframes modalTransition {
        20% {
            opacity:1;
        }
        50% {
            opacity:1;
            width: 100%;
            height: 100%;
            left: 0; 
        }
        75% {
            opacity:1;
            width: 100%;
            height: 100%;  
            border-radius: 60px;
            
        }
        100% {  
            opacity:1;
            width: 100%;
            height: 100%;  
            border-radius: 0; 
            left: 0;
            top: 0;

        }
    }

`